import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/index.ts";
import { useDispatch } from "react-redux";
import {
  setLockBalanceClose,
  setLockFortuneClose,
  setLockLoginClose,
  setModalBalance,
  setModalFortune,
  setModalLogin,
  setTypeLogin
} from "../../store/siteSlice";
import { ENUM_LCS_NAME, ENUM_LOGIN } from "../../store/enums";
import { useReadLocalStorage } from "usehooks-ts";

/**
 * @description не работает для авторизованного и если в ТГ боте
    если человек уже входил, то #signup_on будет работать как signup
 */

export const ENUM_HASH = {
  signup: "#signup",
  signup_on: "#signup_on",
  signup_deposit_on: "#signup_deposit_on",
  fortune: "#fortune",
  fortune_on: "#fortune_on"
};

export default function useUrlParc() {
  const userId = useAppSelector((state) => state.user.userId);
  const isTgBot = useAppSelector((state) => state.common.isTgBot);
  const firstDeposit = useAppSelector((state) => state.promotions.firstDeposit?.values);

  const alreadyAccount = useReadLocalStorage(ENUM_LCS_NAME.alreadyAccount);
  const location = useLocation();
  const dispatch = useDispatch();

  // логика открытия логина и депозита
  useEffect(() => {
    if (userId) return;
    if (isTgBot) return;
    const hash = location.hash;
    const type = alreadyAccount ? ENUM_LOGIN.login : ENUM_LOGIN.register;

    // проверка только на одно, потому что #signup_up включает в себя #signup
    if (alreadyAccount) {
      if (hash.includes(ENUM_HASH.signup)) {
        dispatch(setTypeLogin(type));
        dispatch(setModalLogin(true));
        dispatch(setLockLoginClose(false));
      }
      return;
    }

    // если вообще нет включения #signup то выход
    if (!hash.includes(ENUM_HASH.signup)) return;

    const isLock = hash.includes(ENUM_HASH.signup_on) || hash.includes(ENUM_HASH.signup_deposit_on);
    dispatch(setLockLoginClose(isLock));
    dispatch(setLockBalanceClose(hash.includes(ENUM_HASH.signup_deposit_on)));

    if (hash.includes(ENUM_HASH.signup_deposit_on)) dispatch(setModalBalance(true));

    dispatch(setTypeLogin(type));
    dispatch(setModalLogin(true));
  }, [userId, isTgBot, location, alreadyAccount]);

  // логика открытия колеса фортуны
  useEffect(() => {
    const hash = location.hash;

    // if (!firstDeposit) return;
    if (userId) return;
    if (!hash.includes(ENUM_HASH.fortune)) return;
    const lock = hash.includes(ENUM_HASH.fortune_on) && !alreadyAccount;
    dispatch(setLockFortuneClose(lock));
    dispatch(setModalFortune(true));
  }, [firstDeposit, userId]);
}
