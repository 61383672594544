import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./top-up-balance-bonus.scss";
import { useAppSelector } from "../../../../store/index.ts";
import ShowContainer from "../../../mui/ShowContainer.jsx";
import { useReadLocalStorage } from "usehooks-ts";
import {
  setCashback,
  setFirstDeposit,
  setForUser,
  setRegistration
} from "../../../../store/promotionsSlice.js";
import { ENUM_LCS_NAME, TEST_MODE } from "../../../../store/enums.js";
import BonusClassic from "./BonusClassic.jsx";
import BonusFortune from "./BonusFortune.jsx";
import { dispatchCusEvent } from "../../../../utils/utils.js";
import { ENUM_COMMON_EVENT } from "../../../../hooks/common/useListener.js";

export default function TopUpBonus() {
  const dispatch = useDispatch();
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const isFortune = useReadLocalStorage(ENUM_LCS_NAME.isFortune);

  const userId = useAppSelector((state) => state.user.userId);
  const firstDep = useAppSelector((state) => state.promotions.firstDeposit);
  const cashback = useAppSelector((state) => state.promotions.cashback);
  const forUserBonus = useAppSelector((state) => state.promotions.forUser);

  const bonusCashback = cashback?.values?.percent;
  const bonusWager = firstDep?.values?.wager;

  useEffect(() => {
    if (TEST_MODE) {
      const testDep = { values: { amount: 2000, wager: 2, cashout: 3, minDeposit: 301 } };
      const testReg = { values: { amount: 500, wager: 2, cashout: 3 } };

      dispatch(setCashback(4));
      dispatch(setFirstDeposit(testDep));
      dispatch(setRegistration(testReg));
      dispatch(setForUser(userId));
      return;
    }
    if (userId === forUserBonus) return;

    dispatchCusEvent(ENUM_COMMON_EVENT.getBonus);
  }, [userId, forUserBonus, start_r]);

  return (
    <ShowContainer condition={bonusCashback || bonusWager}>
      {isFortune ? <BonusFortune /> : <BonusClassic />}
    </ShowContainer>
  );
}
