import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useNavigateWithSearch from "../hooks/useNavigateWitchSearch";
import { API_USER } from "../api/user";
import { errorToast, successToast } from "../components/mui/Toaster";
import { messageByCode } from "../utils/utils";
import LoaderContainer from "../components/mui/loader_container/LoaderContainer";

export default function newPassword() {
  const { code } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();

  useEffect(() => {
    console.log("code", code);

    API_USER.newPas({ code })
      .then((e) => {
        const data = e.data;
        const error = e.error;
        const dataError = data.dataError;

        if (data.ok) {
          successToast(t("New password has been sent to your email"));
          return;
        }

        if (dataError) return errorToast(t(messageByCode(dataError)));
        if (error) return errorToast(error);
      })
      .catch(() => {
        errorToast(t("An error occurred"));
      })
      .finally(() => {
        navigate("/");
      });
  }, [code]);

  return <LoaderContainer center loader fullHeight></LoaderContainer>;
}
