import { useDispatch } from "react-redux";
import useLoginAction from "../../useLoginAction.js";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/index.ts";
import FirstBonus from "./first-bonus.png";
import CashBackBonus from "./cashback-bonus.png";
import RegisterBonus from "./register-bonus.png";
import PromoBonus from "./promo-bonus.png";
import { setModalBalance, setPromoCode } from "../../../store/siteSlice.js";
import { useTranslation } from "react-i18next";

export default function useGetBonus() {
  const { t } = useTranslation();
  const { onLoginAction } = useLoginAction();
  const dispatch = useDispatch();
  const cashback = useAppSelector((state) => state.promotions.cashback);
  const firstDeposit = useAppSelector((state) => state.promotions.firstDeposit);
  const registration = useAppSelector((state) => state.promotions.registration);
  const bonusCurrency = useAppSelector((state) => state.promotions.currency);
  const currency = useAppSelector((state) => state.user.currency);
  const [bonuses, setBonuses] = useState([]);

  console.log("bonuses", bonuses);
  console.log("registration", registration);

  useEffect(() => {
    const cashbackValue = cashback?.values;
    const firstDepositValue = firstDeposit?.values;
    const currency = bonusCurrency;

    const bonusFirstDep = {
      id: 0,
      className: "first-deposit",
      title: "Bonus for the first deposit",
      img: FirstBonus,
      onClick: () => {
        onLoginAction(() => dispatch(setModalBalance(true)));
      },
      btnText: t("Terms"),
      open: {
        title: t("Make your first deposit and get up to max currency for FREE!", {
          max: firstDepositValue?.amount,
          currency: currency
        }),
        text: t(
          "Conditions: you need to make a deposit of min currency and your balance will receive double the amount up to max currency. For example, transfer example currency and your balance will be replenished by exampleRes currency.",
          {
            min: firstDepositValue?.minDeposit,
            currency: currency,
            max: firstDepositValue?.amount,
            example: (firstDepositValue?.minDeposit || 100) * 2,
            exampleRes: (firstDepositValue?.minDeposit || 100) * 4
          }
        )
      }
    };

    const bonusCashback = {
      id: 1,
      className: "caskback",
      title: t("Cashback of deposit", { percent: cashbackValue?.percent }),
      img: CashBackBonus,
      onClick: () => {
        onLoginAction(() => dispatch(setModalBalance(true)));
      },
      btnText: "Terms",
      open: {
        title: t("A bonus is available to you - cashback"),
        text: t(
          "A bonus is available to you - cashback. You will instantly receive percent of the deposit amount, wager x, if you make a deposit of min currency or more.",
          {
            percent: cashbackValue?.percent,
            wager: cashbackValue?.wager,
            min: cashbackValue?.minDeposit,
            currency: currency
          }
        )
      }
    };

    const bonusRegister = {
      id: 2,
      className: "register",
      title: "Bonus for registration",
      img: RegisterBonus,
      onClick: () => {
        onLoginAction();
      },
      btnText: t("Terms"),
      open: {
        isTgWidget: true,
        title: t("Registration bonus"),
        text: t(
          "You can receive a registration bonus in our Telegram bot. This is a full-fledged casino bot that will allow you to play, top up and receive winnings without leaving the Telegram messenger.To receive a registration bonus, log in via Telegram and follow the conditions for receiving the bonus."
        )
      }
    };

    const bonusPromo = {
      id: 3,
      className: "promo",
      title: "Bonus by promo code",
      img: PromoBonus,
      onClick: () => {
        onLoginAction(() => dispatch(setPromoCode(true)));
      },
      btnText: "Activate"
    };

    const bonuses = [];

    if (cashback) bonuses.push(bonusCashback);
    if (firstDeposit) bonuses.push(bonusFirstDep);
    if (registration) bonuses.push(bonusRegister);
    bonuses.push(bonusPromo);
    setBonuses(bonuses);
  }, [cashback, firstDeposit, registration, currency]);

  return { bonuses };
}
