import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/index.ts";
import { ENUM_CURRENCY } from "../../../../store/enums";
import imgBonusRUB from "../../fortune_modal/register_fortune/img/bonus-RUB.png";
import imgBonusAZN from "../../fortune_modal/register_fortune/img/bonus-AZN.png";
import imgBonusEURO from "../../fortune_modal/register_fortune/img/bonus-EURO.png";
import imgBonusUSDT from "../../fortune_modal/register_fortune/img/bonus-USDT.png";
import imgBonusUZS from "../../fortune_modal/register_fortune/img/bonus-UZS.png";

export default function BonusFortune() {
  const { t } = useTranslation();

  const currency = useAppSelector((state) => state.user.currency);

  const maxBonusByCurrency = {
    [ENUM_CURRENCY.RUB]: "40 000",
    [ENUM_CURRENCY.AZN]: "700",
    [ENUM_CURRENCY.EUR]: "4 000",
    [ENUM_CURRENCY.USDT]: "4 000",
    [ENUM_CURRENCY.UZS]: "5 500 000"
  };

  const imgBonusByCurrency = {
    [ENUM_CURRENCY.RUB]: imgBonusRUB,
    [ENUM_CURRENCY.AZN]: imgBonusAZN,
    [ENUM_CURRENCY.EUR]: imgBonusEURO,
    [ENUM_CURRENCY.USDT]: imgBonusUSDT,
    [ENUM_CURRENCY.UZS]: imgBonusUZS
  };

  const maxBonus = maxBonusByCurrency[currency];
  const imgBonus = imgBonusByCurrency[currency];

  return (
    <div className="top-up-balance__bonus fortune">
      <div className="top-up-balance__bonus__info">
        <span className="top-up-balance__bonus__info__item">
          <span>{t("Get a plus up to", { max: maxBonus })}</span>
          <span className="currency">{currency}</span>
        </span>
        <img className="img-fortune" src={imgBonus} />
      </div>
    </div>
  );
}
