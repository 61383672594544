import React, { useEffect, useRef, useState } from "react";
import { API_USER } from "../../../api/user";
import { errorToast, successToast } from "../../mui/Toaster";
import { messageByCode } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import CusForm from "../../mui/CusForm";
import CusInput from "../../mui/custom_input/CusInput";
import IconLock from "../../mui/JSXIcons/IconLook";
import CusButton from "../../mui/custom_button/CusButton";
import CusCaptcha from "../../CusCaptcha.jsx";

export default function ProfileNewPas({ closeModal }) {
  const { t } = useTranslation();

  const [pas1, setPas1] = useState("");
  const [pas2, setPas2] = useState("");
  const [error, setError] = useState("");
  const captchaRef = useRef(null);

  const onPasSubmit = (_, fields) => {
    console.log(fields);

    const captcha = captchaRef.current?.getValue?.();

    if (pas1 !== pas2) {
      setError("Passwords must match");
      return;
    }

    API_USER.changePassword({ ...fields, captcha })
      .then((e) => {
        const data = e.data;

        const error = data.dataError || data.error;
        if (error) {
          captchaRef.current?.reset();
          errorToast(data.dataError ? t(messageByCode(data.dataError)) : data.error);
          return;
        }

        successToast(t("Password changed successfully"));
        closeModal();
      })
      .catch((e) => {
        console.log(e);
        captchaRef.current?.reset?.();
      });
  };

  useEffect(() => {
    setError(false);
  }, []);

  return (
    <CusForm className="password" onSubmit={onPasSubmit}>
      <p>{t("Change password")}</p>
      <CusInput
        required
        name={"oldPassword"}
        placeholder={"Current password"}
        type="password"
        iconJSXstart={<IconLock />}
      />
      <CusInput
        required
        name={"newPassword"}
        placeholder={"New password"}
        min={8}
        max={100}
        type="password"
        autocomplete="new-password"
        onChange={(e) => setPas1(e.target.value)}
        iconJSXstart={<IconLock />}
      />
      <CusInput
        required
        name={"newPassword2"}
        placeholder={"Confirm new password"}
        min={8}
        max={100}
        type="password"
        errorWarning={error}
        onChange={(e) => {
          setPas2(e.target.value);
          setError(false);
        }}
        iconJSXstart={<IconLock />}
      />
      <CusCaptcha captchaRef={captchaRef} />

      <div className="buttons">
        <CusButton border text={"Change"} color="gradient-blue" />
        <CusButton text={"Cancel"} type={"reset"} onClick={() => captchaRef.current?.reset()} />
      </div>
    </CusForm>
  );
}
