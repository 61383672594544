import React from "react";
import ShowContainer from "../../../mui/ShowContainer";
import { ENUM_CURRENCY } from "../../../../store/enums";
import imgBonusAZN from "./img/bonus-AZN.png";
import imgBonusEURO from "./img/bonus-EURO.png";
import imgBonusRUB from "./img/bonus-RUB.png";
import imgBonusUSDT from "./img/bonus-USDT.png";
import imgBonusUZS from "./img/bonus-UZS.png";
import "./fortune-bonus.scss";
import { useTranslation } from "react-i18next";

export default function FortuneBonus({ currency, showBonus }) {
  const { t } = useTranslation();
  const bonusByCurrency = {
    [ENUM_CURRENCY.AZN]: imgBonusAZN,
    [ENUM_CURRENCY.EUR]: imgBonusEURO,
    [ENUM_CURRENCY.RUB]: imgBonusRUB,
    [ENUM_CURRENCY.USDT]: imgBonusUSDT,
    [ENUM_CURRENCY.UZS]: imgBonusUZS
  };

  const bonus = bonusByCurrency[currency];

  return (
    <ShowContainer condition={showBonus}>
      <div className="fortune-bonus">
        <img src={bonus} />
        <div className="fortune-bonus__podium">
          <span className="fortune-bonus__title">{t("Your bonus")}</span>
        </div>
      </div>
    </ShowContainer>
  );
}
