import React from "react";
import "./promotions-main.scss";
import FindGame from "../landing/find_game/FindGame";
import StaticBonus from "./static_bonus/StaticBonus";
import Back from "../mui/back/Back";
import IconBonus from "../mui/JSXIcons/IconBonus";
import ErrorBoundary from "../mui/error_boundary/ErrorBoundary.jsx";
import useGetLink from "../../hooks/other/useGetLink.js";

export default function PromotionsMain() {
  const home = useGetLink("/");

  return (
    <section className="promotions">
      <Back icon={<IconBonus />} to={home} title={"Bonuses and promotions"} />
      {/* <TopBonusBanner /> */}
      <FindGame />
      <ErrorBoundary>
        <StaticBonus />
      </ErrorBoundary>
      {/* TODO - пока на сайте нет фриспинов */}
      {/* <FreeSpin /> */}
    </section>
  );
}
