import React, { useRef, useState } from "react";
import CloseModal from "../../../mui/close_modal/CloseModal";
import { useTranslation } from "react-i18next";
import CusButton from "../../../mui/custom_button/CusButton";
import CusForm from "../../../mui/CusForm";
import "./recovery-pas.scss";
import imgLock from "./banner-lock.png";
import { API_USER } from "../../../../api/user";
import EmailInput from "../../../mui/EmailInput.jsx";
import { messageByCode } from "../../../../utils/utils.js";
import CusCaptcha from "../../../CusCaptcha.jsx";

export default function RecoveryPas({ setForgotPas }) {
  const { t } = useTranslation();
  const captchaRef = useRef();
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onFormSubmit = (_, fields) => {
    const { email } = fields;

    console.log("fields", fields);

    API_USER.resetPas({
      email,
      captcha: captchaRef.current?.getValue?.()
    })
      .then((e) => {
        const data = e.data;
        const error = data.dataError || data.error;
        if (error) {
          captchaRef.current?.reset();
          setError(data.dataError ? t(messageByCode(data.dataError)) : data.error);
          return;
        }

        setSuccess(
          `${t("Password change email sent.")} \n ${t("If you didn't see it in your Inbox, check your Spam folder.")}`
        );
      })
      .catch((e) => {
        console.log(e);
      });
    setDisableBtn(false);
  };

  return (
    <div className="recovery-pas">
      <div className="recovery-pas__img">
        <img src={imgLock} height={120} />
      </div>
      <div className="recovery-pas__fields">
        <CloseModal close={() => setForgotPas(false)} />
        <h2>{t("Forgot your password ?")}</h2>
        <p>{t("Enter the e-mail specified during registration")}</p>
        <CusForm className="recovery-pas__fields__forms" onSubmit={onFormSubmit}>
          <EmailInput errorWarning={error} successInfo={success} />

          <CusCaptcha captchaRef={captchaRef} />

          <CusButton
            className="remind-submit"
            text={"Get a new password"}
            type={"submit"}
            disable={disableBtn}
            color="gradient-blue"
            btnLoading={btnLoading}
            setBtnLoading={setBtnLoading}
          />
        </CusForm>
      </div>
    </div>
  );
}
