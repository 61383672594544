import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/index.ts";
import present from "./present.png";

export default function BonusClassic() {
  const { t } = useTranslation();
  const currency = useAppSelector((state) => state.user.currency);
  const firstDep = useAppSelector((state) => state.promotions.firstDeposit);
  const cashback = useAppSelector((state) => state.promotions.cashback);

  const minCashback = cashback?.values?.minDeposit;
  const bonusCashback = cashback?.values?.percent;

  const bonusWager = firstDep?.values?.wager;
  const minFirst = firstDep?.values?.minDeposit;
  const maxFirst = firstDep?.values?.amount;

  const min = minFirst ?? minCashback;

  return (
    <div className="top-up-balance__bonus">
      <div className="top-up-balance__bonus__info">
        <span className="top-up-balance__bonus__info__item">
          <span>
            {t("Top up from")} {min}
          </span>
          <span className="currency">{currency}</span>
        </span>

        {bonusWager ? (
          <div className="top-up-balance__bonus__info__item column">
            <span>{t("Get double the amount!")}</span>

            <div className="bonus-container">
              <span className="bonus">
                {t("to_max_cur", {
                  max: maxFirst,
                  cur: currency
                })}
              </span>
            </div>
          </div>
        ) : (
          <div className="top-up-balance__bonus__info__item">
            <span>{t("Get an extra bonus!")}</span>
            <div className="bonus-container">
              <span className="bonus">+{bonusCashback}%</span>
            </div>
          </div>
        )}
      </div>
      <img src={present} />
    </div>
  );
}
