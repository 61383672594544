import { useEffect, useRef } from "react";
import { useAppSelector } from "../store/index.ts";
import { API_USER } from "../api/user.js";
import { setInfo } from "../store/userSlice.js";
import { useDispatch } from "react-redux";
import { useEventListener, useReadLocalStorage } from "usehooks-ts";
import { ENUM_COMMON_EVENT } from "./common/useListener.js";
import { ENUM_LCS_NAME, TEST_MODE } from "../store/enums.js";
import { testBonus } from "../store/testConst.js";
import {
  setBonusCurrency,
  setCashback,
  setFirstDeposit,
  setForUser,
  setRegistration
} from "../store/promotionsSlice.js";
import { API_CASINO } from "../api/casino.js";
import { dispatchCusEvent } from "../utils/utils.js";

const DELAY_GET_INFO = 0.5 * 1000;

export default function useFillUser() {
  const userId = useAppSelector((state) => state.user.userId);
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const currency = useAppSelector((state) => state.user.currency);

  const dispatch = useDispatch();

  const bonusTmRef = useRef();

  const getInfo = () => {
    if (TEST_MODE) return;
    if (!userId) return;
    API_USER.getProfile()
      .then((e) => {
        dispatch(setInfo(e.data));

        console.log(e);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getBonus = () => {
    if (TEST_MODE) {
      const data = testBonus;
      dispatch(setBonusCurrency(data.currency));
      dispatch(setCashback(data.cashback));
      dispatch(setFirstDeposit(data.firstDeposit));
      dispatch(setRegistration(data.registration));
      return;
    }

    API_CASINO.getBonuses({ start_r })
      .then((e) => {
        const data = e.data;
        dispatch(setBonusCurrency(data.currency));
        dispatch(setCashback(data.cashback));
        dispatch(setFirstDeposit(data.firstDeposit));
        dispatch(setRegistration(data.registration));
        dispatch(setForUser(userId));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEventListener(ENUM_COMMON_EVENT.getInfo, () => getInfo());
  useEventListener(ENUM_COMMON_EVENT.getBonus, () => {
    clearTimeout(bonusTmRef.current);
    bonusTmRef.current = setTimeout(() => getBonus(), DELAY_GET_INFO);
  });

  useEffect(() => {
    getInfo();
  }, [userId]);

  useEffect(() => {
    dispatchCusEvent(ENUM_COMMON_EVENT.getBonus);
  }, [isAuth, userId, currency, start_r]);
}
