import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../store/enums";
import { useAppSelector } from "../store/index.ts";

export default function CusCaptcha({ captchaRef }) {
  const lang = useAppSelector((state) => state.common.lang);

  return <ReCAPTCHA ref={captchaRef} hl={lang} sitekey={SITE_KEY} />;
}
