import React, { useState } from "react";
import imgWheelBorder from "./img/wheel.webp";
import imgWheelBtnBlue from "./img/btn-blue.webp";
import imgWheelBtnRed from "./img/btn-red.webp";
import imgWheelValuesRUB from "./img/fortune-valuers-RUB.png";
import imgWheelValuesUSDT from "./img/fortune-valuers-USDT.png";
import imgWheelValuesAZN from "./img/fortune-valuers-AZN.png";
import imgWheelValuesEURO from "./img/fortune-valuers-EURO.png";
import imgWheelValuesUZS from "./img/fortune-valuers-UZS.png";
import { useTranslation } from "react-i18next";
import { ENUM_CURRENCY } from "../../../../store/enums";
import "./fortune-wheel.scss";

const DELAY_ROTATE = 4 * 1000;

export default function FortuneWheel({ currency, onClick, onBonus }) {
  const { t } = useTranslation();

  const [fortuneClick, setFortuneClick] = useState(false);
  const [fortuneRotate, setFortuneRotate] = useState(false);
  const [fortuneRotated, setFortuneRotated] = useState(false);

  const valuesByCurrency = {
    [ENUM_CURRENCY.AZN]: imgWheelValuesAZN,
    [ENUM_CURRENCY.EUR]: imgWheelValuesEURO,
    [ENUM_CURRENCY.RUB]: imgWheelValuesRUB,
    [ENUM_CURRENCY.USDT]: imgWheelValuesUSDT,
    [ENUM_CURRENCY.UZS]: imgWheelValuesUZS
  };

  const rotateClass = fortuneRotate ? "rotate" : fortuneRotated ? "rotated" : "";

  const onWheelClick = (e) => {
    setFortuneRotated(false);
    setFortuneClick(true);
    setFortuneRotate(true);
    onClick(e);

    setTimeout(() => {
      setFortuneRotate(false);
      setFortuneRotated(true);
      onBonus();
    }, DELAY_ROTATE);
  };

  return (
    <div className={`fortune-wheel ${rotateClass}`} onClick={onWheelClick}>
      <img src={imgWheelBorder} className="fortune-wheel__img-mask-border" />
      <div className="fortune-wheel__border">
        {/* <img src={imgWheelBorder} /> */}
        <div className="fortune-wheel__values">
          <img src={valuesByCurrency[currency]} />
          <button className="fortune-wheel__btn">
            <img src={fortuneClick ? imgWheelBtnRed : imgWheelBtnBlue} />
            <span>{t("Spin")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
