import { createSlice } from "@reduxjs/toolkit";

// - основная информация стора о сайте
const siteSlice = createSlice({
  name: "site",
  initialState: {
    mainLoader: true,
    errorInfo: false,
    pageWitchMenuContent: null,
    pageWitchMenuHeight: window.innerHeight,
    bannerPosition: 0,
    isRunningGame: false,
    openMenu: false,
    serverInfo: {
      online: ""
    },
    lock: {
      loginClose: false,
      balanceClose: false,
      fortuneClose: false
    },
    modals: {
      login: false,
      balance: false,
      withdrawal: false,
      promoCode: false,
      chooseMode: false,
      bonusWagering: false,
      showBalance: false,
      profile: false,
      shownNewPas: false,
      withdrawalLimitation: false,
      fortune: false,
      banners: {
        jackpot: false
      },
      data: {
        chooseMode: {
          game: {},
          cbReal: () => {},
          cbDemo: () => {},
          close: () => {}
        },
        login: {
          cb: () => {},
          type: ""
        },
        banners: {
          jackpot: {}
        }
      }
    }
  },
  reducers: {
    setModalLogin(state, newAction) {
      state.modals.login = newAction.payload;
    },
    setModalBalance(state, newAction) {
      state.modals.balance = newAction.payload;
    },
    setWithdrawal(state, newAction) {
      state.modals.withdrawal = newAction.payload;
    },
    setPromoCode(state, newAction) {
      state.modals.promoCode = newAction.payload;
    },
    setChooseMode(state, newAction) {
      state.modals.chooseMode = newAction.payload;
    },
    setDataChooseMode(state, newAction) {
      state.modals.data.chooseMode = newAction.payload;
    },
    setDataLogin(state, newAction) {
      state.modals.data.login = newAction.payload;
    },
    setBonusWagering(state, newAction) {
      state.modals.bonusWagering = newAction.payload;
    },
    setBannerPosition(state, newAction) {
      state.bannerPosition = newAction.payload;
    },
    setTypeLogin(state, newAction) {
      state.modals.data.login.type = newAction.payload;
    },
    setOpenMenu(state, newAction) {
      state.openMenu = newAction.payload;
    },
    setServerInfo(state, newAction) {
      state.serverInfo = newAction.payload;
    },
    setRunningGame(state, newAction) {
      state.isRunningGame = newAction.payload;
    },
    setShowBalance(state, newAction) {
      state.modals.showBalance = newAction.payload;
    },
    setModalProfile(state, newAction) {
      state.modals.profile = newAction.payload;
    },
    setOpenJackpot(state, newAction) {
      state.modals.banners.jackpot = newAction.payload;
    },
    setDataJackpot(state, newAction) {
      state.modals.data.banners.jackpot = newAction.payload;
    },
    setPageWitchMenuHeight(state, newAction) {
      state.pageWitchMenuHeight = newAction.payload;
    },
    setPageWitchMenuContent(state, newAction) {
      state.pageWitchMenuContent = newAction.payload;
    },
    setMainLoader(state, newAction) {
      state.mainLoader = newAction.payload;
    },
    setShownNewPas(state, newAction) {
      state.modals.shownNewPas = newAction.payload;
    },
    setModalFortune(state, newAction) {
      state.modals.fortune = newAction.payload;
    },
    setWithdrawalLimitation(state, newAction) {
      state.modals.withdrawalLimitation = newAction.payload;
    },
    setErrorInfo(state, newAction) {
      const isError = newAction.payload;
      state.errorInfo = isError;
      if (!isError) {
        state.mainLoader = false;
      }
    },
    setLockLoginClose(state, newAction) {
      state.lock.loginClose = newAction.payload;
    },
    setLockBalanceClose(state, newAction) {
      state.lock.balanceClose = newAction.payload;
    },
    setLockFortuneClose(state, newAction) {
      state.lock.fortuneClose = newAction.payload;
    }
  }
});

export default siteSlice.reducer;

export const {
  setModalLogin,
  setModalBalance,
  setWithdrawal,
  setPromoCode,
  setChooseMode,
  setDataChooseMode,
  setDataLogin,
  setBonusWagering,
  setBannerPosition,
  setTypeLogin,
  setOpenMenu,
  setServerInfo,
  setRunningGame,
  setShowBalance,
  setModalProfile,
  setOpenJackpot,
  setDataJackpot,
  setPageWitchMenuHeight,
  setPageWitchMenuContent,
  setMainLoader,
  setErrorInfo,
  setShownNewPas,
  setWithdrawalLimitation,
  setLockLoginClose,
  setLockBalanceClose,
  setModalFortune,
  setLockFortuneClose
} = siteSlice.actions;
