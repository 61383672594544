import React, { useRef } from "react";
import "./chose-lang.scss";
import { useDispatch, useSelector } from "react-redux";
import IconFlagRu from "../JSXIcons/flag/IconFlagRu";
import IconFlagEn from "../JSXIcons/flag/IconFlagEn";
import IconArrow from "../JSXIcons/IconArrow";
import ShowContainer from "../ShowContainer";
import { setLang } from "../../../store/commonSlice";
import IconFlagUz from "../JSXIcons/flag/IconFlagUz";
import IconFlagAr from "../JSXIcons/flag/IconFlagAr";
import IconFlagAz from "../JSXIcons/flag/IconFlagAz";

export default function ChoseLang({ classNameUl = "" }) {
  const chooseLangRef = useRef(null);
  const dispatch = useDispatch();
  const langList = useSelector((state) => state.common.langList);
  const lang = useSelector((state) => state.common.lang);
  const isExtraSmall = useSelector((state) => state.deviceSize.isExtraSmall);

  const getSelect = (lang) => (lang === lang ? "select" : "");

  const ImgByLang = {
    ru: <IconFlagRu />,
    en: <IconFlagEn />,
    uz: <IconFlagUz />,
    ar: <IconFlagAr />,
    az: <IconFlagAz />
  };

  const titleByLang = {
    ru: "Русский",
    en: "English",
    uz: "O'zbek",
    ar: "عربي",
    az: "Azərbaycan"
  };

  const changeLang = (lang) => {
    dispatch(setLang(lang));
  };

  return (
    <ShowContainer condition={Array.isArray(langList)}>
      <button
        className="chose-lang"
        onMouseOver={() => chooseLangRef.current.classList.remove("close")}>
        <span className="lang-item main select">
          {ImgByLang[lang]}

          <span className="text">
            {!isExtraSmall && titleByLang[lang]}
            <IconArrow className="chose-lang__arrow" />
          </span>
        </span>
        <ul ref={chooseLangRef} className={classNameUl}>
          {langList.map((lang, indx) => (
            <li
              key={indx}
              onClick={() => {
                changeLang(lang);
                chooseLangRef.current.classList.add("close");
              }}
              className={`lang-item ${getSelect(lang)}`}>
              <button>
                {ImgByLang[lang]}
                <span>{titleByLang[lang] || lang}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="chose-lang__plug" />
      </button>
    </ShowContainer>
  );
}
