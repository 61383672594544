import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoaderContainer from "../components/mui/loader_container/LoaderContainer";
import useNavigateWithSearch from "../hooks/useNavigateWitchSearch";
import { API_USER } from "../api/user";
import { errorToast, successToast } from "../components/mui/Toaster";
import { useTranslation } from "react-i18next";
import { dispatchCusEvent, messageByCode } from "../utils/utils";
import { ENUM_COMMON_EVENT } from "../hooks/common/useListener";

export default function ConfirmEmail() {
  const { code } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();

  useEffect(() => {
    console.log("code", code);

    API_USER.confirmEmail({ code })
      .then((e) => {
        const data = e.data;
        const error = e.error;
        const dataError = data.dataError;

        if (data.ok) {
          successToast(t("Mail confirmed successfully"));
          dispatchCusEvent(ENUM_COMMON_EVENT.getInfo);
          return;
        }

        if (dataError) return errorToast(t(messageByCode(dataError)));
        if (error) return errorToast(error);
      })
      .catch(() => {
        errorToast(t("An error occurred"));
      })
      .finally(() => {
        navigate("/");
      });
  }, [code]);

  return <LoaderContainer center loader fullHeight></LoaderContainer>;
}
